@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);

#sub-nav li a::before{
	font-family: FontAwesome;
	letter-spacing: 0;
	margin-right: 0.25em;
}
#sub-nav li.sku-search a::before{
	content: "\f002";
}
#sub-nav li.sku-regist a::before{
	content: "\f044";
}
#sub-nav li.sku-group a::before{
	content: "\f247";
}
#sub-nav li.sku-tag a::before{
	content: "\f02c";
}
#sub-nav li.csv-upload a::before{
	content: "\f093";
}

/*icon*/
span.onetoggle::before {
	content: "\f150";
	font-family: FontAwesome;
	letter-spacing: 0;
	margin-right: 0.25em;
}
span.toggle::before {
	content: "\f150";
	font-family: FontAwesome;
	letter-spacing: 0;
	margin-right: 0.25em;
}
span.modaltoggle::before {
	content: "\f196";
	font-family: FontAwesome;
	letter-spacing: 0;
	margin-right: 0.25em;
}

.fa-tag::before {
	content: "\f02b";
	font-family: FontAwesome;
	letter-spacing: 0;
}
.fa-tags::before {
	content: "\f02c";
	font-family: FontAwesome;
	letter-spacing: 0;
}
.fa-plus-square::before {
	content: "\f0fe";
	font-family: FontAwesome;
	letter-spacing: 0;
}
.fa-cube::before {
	content: "\f1b2";
	font-family: FontAwesome;
	letter-spacing: 0;
}
.fa-shopping-cart::before {
	content: "\f07a";
	font-family: FontAwesome;
	letter-spacing: 0;
}
.fa-truck::before {
	content: "\f0d1";
	font-family: FontAwesome;
	letter-spacing: 0;
}
.fa-building::before {
	content: "\f1ad";
	font-family: FontAwesome;
	letter-spacing: 0;
}
.fa-list-ul::before {
	content: "\f0ca";
	font-family: FontAwesome;
	letter-spacing: 0;
}
.fa-eyedropper::before {
	content: "\f1fb";
	font-family: FontAwesome;
	letter-spacing: 0;
}
.fa-file-text-o::before {
	content: "\f0f6";
	font-family: FontAwesome;
	letter-spacing: 0;
}
.fa-keyboard-o::before {
	content: "\f11c";
	font-family: FontAwesome;
	letter-spacing: 0;
}

span.btn_35{
	display: block;
	width: 35px;
	height: 35px;
	margin: 10px auto;
	text-align: center;
	line-height: 35px;
	color: #0654ba;
	border: 1px solid #eee;
	border-radius: 5px;
	background: #f5f5f5;
	font-family: FontAwesome;
	font-size: 20px;
	cursor: pointer;
}
span.btn_35.arrow-left::before{
	content: "\f060";
}
span.btn_35.arrow-right::before{
	content: "\f061";
}